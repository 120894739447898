import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';

const VideoBox = styled.div.attrs((props) => ({
  className: 'row padding-15 flex-container-column flex-center'
}))`
  > div {
    max-width: 100% !important;
  }
`;

export default class VideoContainer extends Component {
  url = this.props.videoURL || 'https://youtu.be/jvq0JiGqTCM';
  
  render() {
    return (
      <VideoBox>
        <ReactPlayer className="flex-auto padding-15" url={this.url} playing={false} controls={true}/>
      </VideoBox>
    );
  }
}