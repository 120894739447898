import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
// import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../css/App.css';

import ReactGA from 'react-ga';

import MainLayout from './layout/MainLayout';

import Home from '../components/routes/Home/Home';
import Vendors from '../components/routes/Vendors/Vendors';
import PharmaCatalog from '../components/routes/Products/Pharma/Catalog';
import PharmaDetail from '../components/routes/Products/Pharma/Detail';
import ConsumerInfo from '../components/routes/ConsumerInfo';
import WhoWeAre from '../components/routes/WhoWeAre';
import News from '../components/routes/News';
import Careers from '../components/routes/Careers';
import ContactUs from '../components/routes/ContactUs/ContactUs';
import Film from '../components/routes/Film';
import ReturnPolicy from '../components/routes/ReturnPolicy';

import Error from '../components/routes/404';

import {createBrowserHistory as createHistory} from 'history';
import Recall from './routes/Recall';
import Dscsa from './routes/Dscsa';

//google analytics code
ReactGA.initialize('UA-142469589-1');

const history = createHistory();
history.listen((location, action) => {
  if (location.hostname !== 'localhost' || window.location.hostname !== 'dev.arms.avkare.com') {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }
});

export default class App extends Component {
  componentDidMount() {
    if (window.location.hostname !== 'localhost' || window.location.hostname !== 'dev.arms.avkare.com') {
      ReactGA.pageview(window.location.pathname);
    }
  }

  render() {
    return (
      <div>
        <Router history={history}>
          <MainLayout key={history.location.pathname}>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/vendors" component={Vendors} />
              <Route path="/products/pharma/:did" component={PharmaDetail} />
              <Route path="/products/pharma" component={PharmaCatalog} />
              <Route path="/consumer-information" component={ConsumerInfo} />
              <Route path="/who-we-are" component={WhoWeAre} />
              <Route path="/news" component={News} />
              <Route path="/careers" component={Careers} />
              <Route path="/contact" component={ContactUs} />
              <Route path="/film" component={Film} />
              <Route path="/returnpolicy/" component={ReturnPolicy} />
              <Route path="/recall" component={Recall} />
              <Route page="/dscsa" component={Dscsa} />
              <Route component={Error} />
            </Switch>
          </MainLayout>
        </Router>
      </div>
    );
  }
}
