import React, { Component } from 'react';
import { Row, Nav, NavDropdown, Navbar } from 'react-bootstrap';
import '../../css/NavBar.css';

import avkareLogo from '../../assets/images/logos/AvKARE_slogan.png';

export default class CustomNavBar extends Component {
  componentDidMount() {
    window.addEventListener('keydown', this.handleFirstTab);
    console.log('loaded');
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleFirstTab);
  }

  handleFirstTab = (e) => {
    if (e.keyCode === 9) {
      // the "I am a keyboard user" key
      document.body.classList.add('user-is-tabbing');
      window.removeEventListener('keydown', this.handleFirstTab);
    }
  };

  render() {
    return (
      <Row id="navBar" className='bg-light'>
        <Navbar collapseOnSelect expand="lg" className="redStrip bg-light" id="navToggle">
          <Navbar.Brand href="/">
            <img
              className="avkareLogo align-top"
              src={avkareLogo}
              alt="AvKARE"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />

          <Navbar.Collapse id="navbarContent">
            <Nav className="navigationContent">
              <Nav.Link href='/'>
                Home
              </Nav.Link>
              <NavDropdown title="Products" id="basic-nav-dropdown">
                <NavDropdown.Item href="/products/pharma">Pharma Catalog</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/consumer-information">
                  Consumer Information
                </NavDropdown.Item>
                
              </NavDropdown>

              <NavDropdown title="Buy" id="basic-nav-dropdown">
                <NavDropdown.Item
                    href="https://store.avkare.com/"
                    target="_blank"
                    rel="noopener noreferrer">
                    AvKARE Online Store
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="https://orderdirect.avkare.com/store"
                    target="_blank"
                    rel="noopener noreferrer">
                    AvKARE Order Direct
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item
                    href="https://signup.avkare.com/"
                    target="_blank"
                    rel="noopener noreferrer">
                    Become a New Customer
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/dscsa">
                    DSCSA Information
                  </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="About Us" id="basic-nav-dropdown">
                <NavDropdown.Item href="/who-we-are">Who We Are</NavDropdown.Item>
                <NavDropdown.Item href="/contact">Contact Us</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="News" id="basic-nav-dropdown">
                <NavDropdown.Item href="/news">News</NavDropdown.Item>
                <NavDropdown.Item href="/recall">Recall Notices</NavDropdown.Item>
                <NavDropdown.Item href='/dscsa'>DSCSA</NavDropdown.Item>
              </NavDropdown>

              <Nav.Item>
                <Nav.Link href="/careers">
                  Careers
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link href="/dscsa">
                  DSCSA
                </Nav.Link>
              </Nav.Item>
{/* 
              <Nav.Item>
                <Nav.Link href="/contact">
                  Contact Us
                </Nav.Link>
              </Nav.Item> */}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        </Row>
    );
  }
}
