import React, { Component } from 'react';

import '../../css/dscsa.css';
import VideoContainer from '../layout/VideoContainer';

export default class Dscsa extends Component {
  constructor(props) {
    super(props);
    this.state = { loadPending: false };
  }

  componentDidMount() {
    document.title = 'AvKARE | DSCSA';
  }

  render() {
    return (
      <div className="page colorBg" id="dscsa">

        <div className="row colorBg" id="logistics">
          <div className="col-12 col-md-6 order-12 order-md-1 text-white my-auto">
            <div className="box mx-auto">
              <p>
                <span className="h4 text-uppercase semiBold d-block">
                  How to get your GLN #
                </span>
                If you do not already have your GLN number, watch this short video
                for a demonstration.
              </p>
              <a href="https://www.gs1us.org/industries-and-insights/standards/global-location-number/get-a-global-location-number"
                rel="noopener noreferrer"
                target="_blank"
                className="btn btnCustom text-uppercase"
              >
                Get a GLN
              </a>
            </div>
          </div>
          <div className="col-12 col-md-6 order-1 order-md-12 p-0">
            <VideoContainer videoURL="https://www.youtube.com/watch?v=nYQZW8rjR1U"/>
          </div>
        </div>

        <div className="row colorBg links">
          <div className="col-12  mb-5 mt-5">
            <div className="width mx-auto">
              <p>
                <span className="h3 textRed semiBold d-block">
                  Important Dispenser Links
                </span>
                
              </p>
              <ul className="font-weight-bold">
                <li>
                    <a href='https://www.fda.gov/drugs/drug-supply-chain-security-act-dscsa/drug-supply-chain-security-act-product-tracing-requirements-frequently-asked-questions#q5'>FDA DAQ's on DSCSA</a>
                </li>
                <li>
                    <a href='http://(dscsa.pharmacy)%20//%20https://www.ashp.org/-/media/assets/pharmacy-practice/resource-centers/drug-supply-chain/docs/ASHP-Drug-Supply-Chain-and-Security-Act-Requirements.pdf'>Dispenser EDU</a>
                </li>
                <li>
                    <a href='https://pdsaonline.org/wp-content/uploads/2020/10/PDSA-QA-Document-on-Dispenser-Requirements_Final-1.pdf'>Dispenser Requirements PDSA QA Document</a>
                </li>
                <li>
                    <a href='http://(dscsa.pharmacy)/'>Resources - Dispenser EDU</a>
                </li>
            </ul>
              
            </div>
          </div>
        </div>

        <div className="row colorBg products">
          <div className="col-12 col-md-6 p-0">
            <VideoContainer videoURL="https://www.youtube.com/watch?v=0WJyem46UAQ"/>
          </div>
          <div className="col-12 col-md-6 text-white my-auto">
            <div className="box mx-auto">
              <p>
                <span className="h4 text-uppercase semiBold d-block">
                    DSCSA 2023: The Long Road Ahead
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className='row colorBg links'>
            <div className='col-12 mt-5 mb-5'>
                <div className="width mx-auto">
                    <p>
                        <span className="h3 textRed semiBold breakWord d-block">
                            Important GS1 Information
                        </span>
                    </p>

                    <ul className="font-weight-bold">
                        <li>
                            <a href='https://www.gs1us.org/tools/gs1-us-data-hub'>GS1US Data Hub</a>
                        </li>
                        <li>
                            <a href='https://www.gs1.org/standards/healthcare-gln-implementation-guideline/current-standard#1-Introduction'>GS1 Healthcare GLN Implementation Guideline</a>
                        </li>
                        <li>
                            <a href='https://www.gs1.org/docs/healthcare/Publications_position-papers/Aggregation-Paper-Pharma-Supply-Chain.pdf'>GS1 Aggregation in the Pharma Supply Chain</a>
                        </li>
                    </ul>

                    
                </div>
            </div>
            
        </div>
        <div className="row colorBg products">
            <div className="col-12 col-md-6 p-0">
                <VideoContainer videoURL="https://www.youtube.com/watch?v=qdSMEqcBVrU"/>
            </div>
            <div className="col-12 col-md-6 text-white my-auto">
                <div className="box mx-auto">
                    <p>
                        <span className="h4 text-uppercase semiBold d-block">
                        Healthcare distributors: Health Delievered
                        </span>
                        
                    </p>
                
                </div>
            </div>
        </div>
        <div className='row colorBg links'>
            <div className='col-12 mt-5 mb-5'>
                <div className="width mx-auto">
                    <p>
                        <span className="h3 textRed semiBold breakWord d-block">
                            Pharmacy Information Resources
                        </span>
                    </p>
                    <ul className="font-weight-bold">
                        <li>
                            <a href='https://nabp.pharmacy/resources/?post_type%5B%5D=resource_files&post_type%5B%5D=resources&s=dscsa'>NABP Understanding DSCSA Requirement and Common Diversion Scheme</a>
                        </li>
                        <li>
                            <a href='https://nabp.pharmacy/news/blog/how-pharmacies-can-prep-now-for-the-2023-dscsa-requirements/?utm_source=iContact&utm_medium=email&utm_campaign=nabp-marketing&utm_content=FINAL_All_Lead_Customer_Blog+For+9%2F29'>NABP Blog on DSCSA</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

        
    );
  }
}
